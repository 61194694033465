
// Primary-colors
// $primary-color:  #22b0fc; //color for: Page = 1
// $primary-color-p3:  #0f53fa; //color for: Page = 3
// $primary-color-p4:  #5473E8; //color for: Page = 4
// $primary-color-p5:  #10d396; //color for: Page = 5

// Secondary-colors
// $secondary-color: #f77321; 
// $secondary-color-p3: #ffd166; //color for: Page = 3
// $secondary-color-p4: #36c0cd; //color for: Page = 4


// Global variables
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

$theme-base-font:'Lato', Arial, Helvetica, sans-serif;

// Landing1 Fonts and colors
$l1-primary-font: $theme-base-font;
$l1-primary-heading: #19191b;
$l1-primary-text: #696871;
$l1-primary-color: #0BCDB3;
$l1-primary-bg: #182635;
$l1-secondary-color: #F26444;
$l1-btn-border: #0BCDB3;
// $l1-btn-hover: #F9544B;

// Landing2 Fonts and colors
$l2-primary-font: $theme-base-font;
$l2-primary-heading: #19191b;
$l2-primary-text: #696871;
$l2-primary-color: #0BCDB3;
$l2-secondary-color: #F26444;
$l2-btn-border: #0BCDB3;

// Landing3 Fonts and colors
$l3-primary-font: $theme-base-font;
$l3-primary-heading: #19191b;
$l3-primary-text: #696871;
$l3-primary-color: #0BCDB3;
$l3-btn-border: #c31a12;
$l3-btn-hover: #F9544B;
$l3-secondary-color: #F26444;
$l3-body-bg: #19191b;

// Landing4 Fonts and colors
$l4-primary-font: $theme-base-font;
$l4-primary-heading: #19191b;
$l4-primary-text: #696871;
$l4-primary-color: #fedc5a;
$l4-btn-border: #f0c931;
$l4-btn-hover: #F9544B;
$l4-secondary-color: #5454d4;


// Landing5 Fonts and colors
$l5-primary-font: $theme-base-font;
$l5-primary-heading: #19191b;
$l5-primary-text: #696871;
$l5-primary-color: #0BCDB3;
$l5-btn-border: #070606;
$l5-btn-hover: #0BCDB3;
$l5-secondary-color: #5454d4;


// Landing6 Fonts and colors
$l6-primary-font: $theme-base-font;
$l6-primary-heading: #19191b;
$l6-primary-text: #696871;
$l6-primary-color: #0BCDB3;
$l6-btn-border: #0BCDB3;
$l6-secondary-color: #5454d4;

// Landing7 Fonts and colors
$l7-primary-font: $theme-base-font;
$l7-primary-heading: #19191b;
$l7-primary-text: #696871;
$l7-primary-color: #0BCDB3;
$l7-btn-border: #0BCDB3;
$l7-secondary-color: #5454d4;
$l7-body-bg: #fcfdfe;

// Landing8 Fonts and colors
$l8-primary-font: $theme-base-font;
$l8-primary-heading: #19191b;
$l8-primary-text: #696871;
$l8-primary-color: #0BCDB3;
$l8-secondary-color: #5454d4;
$l8-body-bg: #19191b;
// $l1-border: #f04037;

$theme-primary-font: $theme-base-font;
$theme-primary-heading: #19191b;
$theme-primary-text: #696871;
$theme-primary-color: #0BCDB3;
$theme-secondary-color: #F26444;

$theme-red-color: #f04037;
$theme-red-hover: #F9544B;
$theme-red-color-2: #c31a12;
$theme-blue-color: #5454d4;
$theme-blue-color-2: #6565e9;
$theme-blue-color-3: #3d3dad;
$theme-purple-color: #a665fb;
$theme-green-color: #77bf41;
$theme-yellow-color: #fedc5a;
$theme-ash-color: #413e65;
$theme-ash-color-2: #666666;
$theme-black-color: #182635;
$theme-black-color-2: #1e1e20;
$theme-black-color-3: #1F1F1F;



// Screen Width
$screen-xxs: 320px;
$screen-xs: 480px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1366px;
$screen-xxxl: 1600px;

.bg-whisper{
  background: #F7F7FB;
}