@import 'helpers/variables';
@import 'helpers/mixins';

/*==== Contact_Pages -------*/
.contact-page .section-title {
  margin-bottom: 0px;
}

@media (min-width: 992px) {
  .contact-page .section-title {
    margin-bottom: 0px;
  }
}

.inner-hero-area .inner-hero-text .title {
  margin-bottom: 52px;
  font-size: 66px;
}

.inner-hero-area {
  padding-top: 190px;
  padding-bottom: 80px;
}

.omega-contact-form .form-control {
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #ffffff;
  color: #9c9aaa;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0px;
  margin-bottom: 11px;
  text-indent: 25px;
}

.contact-widget-block p {
  color: #696871;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 58px;
  margin-bottom: 15px;
}

.contact-widget-block a {
  color: #696871;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 58px;
  margin-bottom: 15px;
}

.brand-primary-title {
    color: $l1-primary-color;
    font-family: $l1-primary-font;
}

.brand-primary {
    color: $l1-primary-color;
}

.paddingtext{
padding-bottom: 30px;
}

.section-padding {
  padding-top: 100px;
  padding-bottom: 100px;
}

.tablet-img-main  {
    text-align: center;
    @include desktops{
      text-align: right;
    }
    img{
      max-width: 80%;
      @include brk-point(400px){
        max-width: 50%;
      }
      @include mobile-lg{
        max-width: 60%;
      }
      @include desktops{
        margin-left: -239px;
        max-width: 559px;
        transform: rotate(6deg)

      }
    }
  }

.brand-logo a img {
    height: 44px;
}

.footer-logo img {
    height: 44px;
}

dl.terms-and-conditions-dl {
  display: flex;
  flex-wrap: wrap;
  color: #696871;
  font-size: 16px;
  font-weight: 300;
  dt {
    width: 20%;
  }
  dd {
    width: 70%;  
  }
}

.privacy-policy-list {
  list-style-type: disc;
  margin-left: 20px;
  color: #696871;
  font-size: 16px;
  font-weight: 300;  

  li {
    margin: 0 0 20px 0;
  }
}