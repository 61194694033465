@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
.bg-whisper {
  background: #F7F7FB;
}

/*=== Media Query ===*/
/*==== Contact_Pages -------*/
.contact-page .section-title {
  margin-bottom: 0px;
}

@media (min-width: 992px) {
  .contact-page .section-title {
    margin-bottom: 0px;
  }
}

.inner-hero-area .inner-hero-text .title {
  margin-bottom: 52px;
  font-size: 66px;
}

.inner-hero-area {
  padding-top: 190px;
  padding-bottom: 80px;
}

.omega-contact-form .form-control {
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #ffffff;
  color: #9c9aaa;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0px;
  margin-bottom: 11px;
  text-indent: 25px;
}

.contact-widget-block p {
  color: #696871;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 58px;
  margin-bottom: 15px;
}

.contact-widget-block a {
  color: #696871;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 58px;
  margin-bottom: 15px;
}

.brand-primary-title {
  color: #0BCDB3;
  font-family: "Lato", Arial, Helvetica, sans-serif;
}

.brand-primary {
  color: #0BCDB3;
}

.paddingtext {
  padding-bottom: 30px;
}

.section-padding {
  padding-top: 100px;
  padding-bottom: 100px;
}

.tablet-img-main {
  text-align: center;
}

@media (min-width: 992px) {
  .tablet-img-main {
    text-align: right;
  }
}

.tablet-img-main img {
  max-width: 80%;
}

@media (min-width: 400px) {
  .tablet-img-main img {
    max-width: 50%;
  }
}

@media (min-width: 576px) {
  .tablet-img-main img {
    max-width: 60%;
  }
}

@media (min-width: 992px) {
  .tablet-img-main img {
    margin-left: -239px;
    max-width: 559px;
    transform: rotate(6deg);
  }
}

.brand-logo a img {
  height: 44px;
}

.footer-logo img {
  height: 44px;
}

dl.terms-and-conditions-dl {
  display: flex;
  flex-wrap: wrap;
  color: #696871;
  font-size: 16px;
  font-weight: 300;
}

dl.terms-and-conditions-dl dt {
  width: 20%;
}

dl.terms-and-conditions-dl dd {
  width: 70%;
}

.privacy-policy-list {
  list-style-type: disc;
  margin-left: 20px;
  color: #696871;
  font-size: 16px;
  font-weight: 300;
}

.privacy-policy-list li {
  margin: 0 0 20px 0;
}
